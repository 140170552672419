<template>
    <v-row>
        <v-col
            cols="12"
            class="text-left"
        >
            <v-card
                v-if="purpose"
                color="dark"
                dark
                class="rounded-xl"
            >
                <purposeText :purpose="purpose">
                    <v-card-subtitle class="text-h7 ma-0 pa-0">
                        <span class="text-h7 mx-4 my-0 pa-0">{{ $t("purposes.groupTitle") }}</span>
                    </v-card-subtitle>
                </purposeText>

                <v-divider />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import purposeText from '@/components/purpose/purposeText'

export default {
    name: 'PurposeGroupSingle',
    components: { purposeText },
    props: {
        purpose: {
            type: Object,
            default() {
                return null;
            }
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
    }
}
</script>

<style scoped>

</style>
