<template>
    <v-card>
        <v-card-title class="pb-0 mb-0">
            <v-container
                class="d-flex justify-space-between flex-wrap ma-0 pa-0"
                fluid
            >
                <span
                    class="text-h5"
                > {{ $t('admin.groups.title') }}</span>
                <!--                <v-btn
                    class="ma-2"
                    dark
                    color="primary"
                    @click="createItem"
                >
                    <v-icon>mdi-plus</v-icon>
                    {{ $t('admin.groups.add') }}
                </v-btn>-->
            </v-container>
        </v-card-title>

        <v-card-text>
            <v-row
                class="pa-4"
                justify="space-between"
            >
                <v-col
                    cols="12"
                    md="6"
                >
                    <v-treeview
                        :open="initiallyOpen"
                        :items="items"
                        item-key="id"
                        return-object
                        expand-icon="mdi-chevron-down"
                        :active.sync="active"
                        color="primary"
                        transition
                        activatable
                    />
                </v-col>

                <v-divider vertical />

                <v-col
                    class="d-flex text-center"
                >
                    <v-scroll-y-transition mode="out-in">
                        <div
                            v-if="!selected"
                            class="text-h6 grey--text text--lighten-1 font-weight-light"
                            style="align-self: center;"
                        >
                            {{ $t('admin.groups.selectAGroup') }}
                        </div>
                        <v-card
                            v-else
                            :key="selected.id"
                            class="mx-auto"
                            flat
                            max-width="1200"
                            width="80%"
                        >
                            <v-card-text class="mx-0 px-0 mb-0 pb-0">
                                <h3 class="text-h5 mb-2">
                                    {{ selected.name }}
                                </h3>
                                <!-- usersCount, countEntered, countDraft, countPublished-->

                                <div
                                    v-if="usersCountByGroup[selected.id]"
                                    class="text-info-group mb-3"
                                    style="display: grid; grid-template-columns: 35% auto; column-gap: 6px; text-align: left !important;"
                                >
                                    <strong style="justify-self: right">
                                        {{ usersCountByGroup[selected.id].usersCount }}</strong>
                                    <span style="justify-self: left">
                                        {{ $t('admin.groups.userTotal') }}
                                    </span>

                                    <strong style="justify-self: right">
                                        {{ usersCountByGroup[selected.id].countEntered }}</strong>
                                    <span style="justify-self: left">
                                        {{ $t('admin.groups.usersEntered') }}
                                    </span>

                                    <strong style="justify-self: right">
                                        {{ usersCountByGroup[selected.id].countDraft }}</strong>
                                    <span style="justify-self: left">
                                        {{ $t('admin.groups.usersHaveDraft') }}
                                    </span>

                                    <strong style="justify-self: right">
                                        {{ usersCountByGroup[selected.id].countPublished }}</strong>
                                    <span style="justify-self: left">
                                        {{ $t('admin.groups.usersHaveMissionPublished') }}
                                    </span>
                                </div>
                                <div
                                    v-else
                                    style="height: 6.75rem; width: 100%; padding-top: 2rem;"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="blue lighten-5"
                                        size="32"
                                    />
                                </div>
                            </v-card-text>

                            <div
                                v-if="selected.id > 4"
                                style="display: flex; justify-content: center"
                                class="mb-4"
                            >
                                <v-btn
                                    dark
                                    color="primary"
                                    @click="exportReport(selected.id, selected.name)"
                                >
                                    <v-icon>mdi-file-chart</v-icon>
                                    {{ $t('admin.groups.exportAreaReport') }}
                                </v-btn>
                            </div>

                            <v-divider />

                            <v-row
                                class="text-left pb-0 mb-0"
                                tag="v-card-text"
                                no-gutters
                            >
                                <v-col
                                    class="text-right mr-4 my-0 py-0"
                                    tag="strong"
                                    cols="5"
                                >
                                    {{ $t('purposes.published') + ':' }}
                                </v-col>
                                <v-col class="my-0 py-0">
                                    {{ dateHelper.getDateTimeTableFormat(selected.createdAt) }}
                                </v-col>
                            </v-row>

                            <v-row
                                class="text-left py-0"
                                tag="v-card-text"
                                no-gutters
                            >
                                <v-col
                                    class="text-right mr-4 mt-0 py-0 mb-4"
                                    tag="strong"
                                    cols="5"
                                >
                                    {{ $t('admin.common.updatedAt') + ':' }}
                                </v-col>
                                <v-col class="my-0 py-0">
                                    {{ dateHelper.getDateTimeTableFormat(selected.updatedAt) }}
                                </v-col>
                            </v-row>

                            <v-divider />

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mr-4 mt-4"

                                        v-bind="attrs"
                                        @click="createItemChildren(selected)"
                                        v-on="on"
                                    >
                                        mdi-plus-box-multiple
                                    </v-icon>
                                </template>

                                <span>{{ $t('admin.groups.addChildren') }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mr-4 mt-4"

                                        v-bind="attrs"
                                        @click="editItem(selected)"
                                        v-on="on"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </template>

                                <span>{{ $t('common.edit') }}</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="mt-4"
                                        small
                                        v-bind="attrs"
                                        @click="deleteItem(selected)"
                                        v-on="on"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </template>

                                <span>{{ $t('common.delete') }}</span>
                            </v-tooltip>

                            <v-divider
                                class="my-4"
                            />

                            <purposeGroupSingle
                                :purpose="selected"
                            />
                        </v-card>
                    </v-scroll-y-transition>
                </v-col>
            </v-row>
        </v-card-text>

        <groups-create
            v-if="dialog"
            :dialog="dialog"
            :item="itemSelected"
            :items="itemsArray"
            @update:dialog="closeDialog($event) "
            @update:table="fetchGroups"
        />

        <confirm-dialog
            ref="confirm"
        />
    </v-card>
</template>

<script>
import axios from '@/plugins/axios'
import groupsCreate from '@/components/admin/groups/groupsCreate'
import purposeGroupSingle from '@/components/purpose/purposeGroupSingle'

export default {
    name: 'GroupsTreeList',
    components: {
        groupsCreate,
        purposeGroupSingle
    },
    data: function () {
        return {
            urlMain: '/groups',
            active: [],
            initiallyOpen: [],
            items: [], // items in tree format
            itemsArray: [], // items in ArrayList format
            dialog: false,
            itemSelected: null,
            usersCountByGroup: {}

        }
    },
    computed: {
        selected() {
            if (!this.active.length) return undefined
            return this.active[0]
        }
    },
    watch: {
        active: {
            async handler(newValue, oldValue) {
                if (this.active.length) {
                    const groupId = this.active[0].id

                    if (!this.usersCountByGroup[groupId]) {
                        const { usersCount, data } = await this.fetchUsersCountByGroup(groupId);

                        const { countEntered, countDraft, countPublished } = data;
                        // console.log("data del grupo ", data);
                        this.$set(this.usersCountByGroup, groupId, { usersCount, countEntered, countDraft, countPublished });
                        //  this.usersCountByGroup[groupId] = count
                    }
                }
            }

        }

    },
    created() {
        this.fetchGroups()
    },
    methods: {
        /* remove(item) {
      this.itemsSelected = this.itemsSelected.filter(e => item.id !== e);
  }, */
        async fetchUsersCountByGroup(groupId) {
            try {
                const groups = await axios
                    .get('getUsersCountByGroup/' + groupId)

                const { usersCount, data } = groups.data

                return { usersCount, data }
            } catch (e) {

            }
            return null;
        },
        async fetchGroups() {
            try {
                const groups = await axios
                    .get(this.urlMain)

                const data = groups.data.data
                this.itemsArray = data

                const map = {}
                const items = []

                for (let i = 0; i < data.length; i++) {
                    const item = data[i]
                    const parentId = item.attributes['parent-id']
                    if (parentId) {
                        if (!map[parentId]) map[parentId] = []

                        map[parentId].push(item)
                    } else {
                        items.push(item)
                    }
                }

                this.items = this.fullfillTree(items, map)

                /* console.log('***** fullfillTree *********');
console.log(this.items); */
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        fullfillTree(items, map) {
            const result = []
            for (let i = 0; i < items.length; i++) {
                const item = items[i]
                const parent = {
                    id: item.id,
                    name: item.attributes.name,
                    body: item.attributes.body,
                    createdAt: item.attributes['created-at'],
                    updatedAt: item.attributes['updated-at'],
                    parentId: item.attributes['parent-id']
                }

                if (map[item.id]) {
                    const children = map[item.id]
                    if (children && children.length) {
                        parent.children = this.fullfillTree(children, map)
                    }
                }

                result.push(parent)
            }

            return result
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : ''

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend)

                if (result.status === 204) {
                    this.active = []

                    this.fetchGroups()
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                /* if (error.response) {
  console.log(error.response.data.error) // "invalid_credentials"
  console.log(error.response.status)
  console.log(error.response.headers)
} */
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },
        editItem(item) {
            this.itemSelected = item
            this.dialog = true
        },
        createItemChildren(item) {
            this.itemSelected = { parentId: item.id }
            this.dialog = true
        },
        createItem() {
            this.itemSelected = null
            this.dialog = true
        },
        closeDialog(option) {
            this.dialog = option
            this.active = []
        // this.$emit('update:dialog', false)
        },
        async exportReport(idArea, nameArea) {
            this.$store.dispatch('loading/start');

            try {
                const res = await axios
                    .get('exportRankingFromAreaToExel', {
                        responseType: 'arraybuffer',
                        params: {
                            idArea,
                            nameArea
                        }
                    });

                const blob = new Blob([res.data], { type: 'application/*' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `Reporte de Area - ${nameArea} ${this.dateHelper.currentDateTimeToFileName()} .xlsx`;
                link._target = 'blank';
                link.click();
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }

            this.$store.dispatch('loading/end');
        }
    }
}
</script>

<style>

.text-info-group{
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: 0.0071428571em;
}

.v-subheader {
  align-items: center;
  display: flex;
  height: 20px;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 10px 16px 0 16px;
}
</style>
