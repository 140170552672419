<template>
    <div>
        <groups-component />
    </div>
</template>

<script>
import GroupsComponent from '@/components/admin/groups/groupsTreeList'
export default {
    name: "AdminGroups",
    components: { GroupsComponent },
    data: () => ({

    })
};
</script>
